import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'
const configFirebase = {
  apiKey: "AIzaSyA2olMRrDCwMtbdztIeojYL7JZkBv0I6q4",
  authDomain: "lotto-visa.firebaseapp.com",
  databaseURL: "https://lotto-visa-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "lotto-visa",
  storageBucket: "lotto-visa.appspot.com",
  messagingSenderId: "815103051643",
  appId: "1:815103051643:web:c01ae2c53190e2b9c5dfde"
}
firebase.initializeApp(configFirebase)
Vue.config.productionTip = false
Vue.prototype.$music = null
let app: any
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
